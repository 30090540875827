import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import logo from "./logo.png";
import dexsc from "./assets/icons/dexscreener-logo.svg";
import dext from "./assets/icons/dextools-logo.svg";
import twitter from "./assets/icons/twitter-logo.svg";
import tg from "./assets/icons/telegram-logo.svg";
import "./App.css";
import AppRoutes from "./routes";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav>
            <Link to="/"><img src={logo} className="logo" alt="logo" /></Link>
            <div className="nav-bar">
              <Link className="nav-item" to="/meme-maker">Meme Maker</Link>
              <div className="socials">
                <a
                  href="https://x.com/jinxthecoin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="twitter" />
                </a>
                <a
                  href="https://t.me/jinxportal"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={tg} alt="telegram" />
                </a>
                <a
                  href="https://dexscreener.com/solana/h72lgm51kajbhjnixh3q3vakqzxtoeubhfvfawpt9jmr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={dexsc} alt="dexscreener" />
                </a>
                <a
                  href="https://www.dextools.io/app/en/solana/pair-explorer/H72LGm51KajBhJniXh3Q3VakQzXToEuBHFvfAWPT9JmR?t=1726357743264"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={dext} alt="dextools" />
                </a>
              </div>

            </div>
            <div className="m-socials">
              <a
                href="https://x.com/jinxthecoin"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="twitter" />
              </a>
              <a
                href="https://t.me/jinxportal"
                target="_blank"
                rel="noreferrer"
              >
                <img src={tg} alt="telegram" />
              </a>
              <a
                href="https://dexscreener.com/solana/h72lgm51kajbhjnixh3q3vakqzxtoeubhfvfawpt9jmr"
                target="_blank"
                rel="noreferrer"
              >
                <img src={dexsc} alt="dexscreener" />
              </a>
              <a
                href="https://www.dextools.io/app/en/solana/pair-explorer/H72LGm51KajBhJniXh3Q3VakQzXToEuBHFvfAWPT9JmR?t=1726357743264"
                target="_blank"
                rel="noreferrer"
              >
                <img src={dext} alt="dextools" />
              </a>
            </div>
          </nav>
        </header>
        <AppRoutes />
      </div>
    </Router>
  );
}

export default App;
